import { ReactNode, useMemo } from "react";
import { createManualStoreContext } from "../util/createProvider";
import { GetUserByEmailDocument, UpdateUserDocument } from "../../gql/graphql";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as dayjs from "dayjs";
import { post } from "../util/axios";
import { GenericDataApiResponse } from "../types";
import { useNotify } from "../util/notify";
import { t } from "@lingui/macro";

/**
 * Custom React hook for managing user related data and actions.
 *
 */
function useUserStore() {
  const userConnected = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") as string)
    : null;

  const { data: userData, refetch: userConnectedRefetch } = useQuery(GetUserByEmailDocument, {
    variables: { email: userConnected ? userConnected.email : "" },
  });

  const [getUserByEmailQuery, { data: getUserByEmailData, loading: getUserByEmailLoading }] =
    useLazyQuery(GetUserByEmailDocument);

  const [updateUser] = useMutation(UpdateUserDocument);

  const notify = useNotify();

  const userIsSubscribed = useMemo(() => {
    return userData ? !!userData?.getUserByEmail?.subscriptionDate : false;
  }, [userData]);

  /**
   * Update the subscription for the user.
   *
   */
  const updateSubscription = async () => {
    updateUser({
      variables: {
        updateUserInput: {
          _id: userData?.getUserByEmail?._id as string,
          subscriptionDate: dayjs().format("YYYY-MM-DD"),
        },
      },
    });
  };

  /**
   * Checkout a subscription if not already subscribed.
   *
   * @param {boolean} isSubscribed - indicates if user is already subscribed
   * @return {void}
   */
  const checkoutSubscription = async (isSubscribed: boolean) => {
    if (isSubscribed) {
      // eslint-disable-next-line no-console
      console.info("Already subscribed");
      return;
    }

    if (!isSubscribed) {
      window.location.href = import.meta.env.VITE_LEMONSQUEEZIE_STORE_URL;
    }
  };

  const cancelSubscription = async () => {
    if (!userData?.getUserByEmail?.email) return;
    const res: GenericDataApiResponse = await post("cancel-subscription", {
      email: userData?.getUserByEmail?.email as string,
    });
    if (!res.data.success) {
      console.error("Subscription cancelled error");
    }
    notify.success(t`Abonnement annulé`);
    userConnectedRefetch({
      email: userData?.getUserByEmail?.email as string,
    });
  };

  return {
    userConnected,
    updateSubscription,
    userIsSubscribed,
    checkoutSubscription,
    userData,
    userConnectedRefetch,
    cancelSubscription,
    getUserByEmailQuery,
    getUserByEmailData,
    getUserByEmailLoading,
  };
}

const { StoreProvider, useStore } = createManualStoreContext(useUserStore);

type UserProviderProps = {
  children: ReactNode;
};

/**
 * UserProvider function to provide user information to the children.
 *
 * @param {UserProviderProps} children - The children to provide user information to.
 * @return {JSX.Element} The React element to be rendered.
 */
export function UserProvider({ children }: UserProviderProps) {
  const userStore = useUserStore();

  return <StoreProvider store={userStore}>{children}</StoreProvider>;
}

export const useUser = useStore;
