import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode, Suspense, useEffect } from "react";
import { routeTree } from "./routeTree.gen";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CONFIG, apiUrl } from "./config";
import { AuthProvider } from "./provider/auth.provider";
import { I18nProvider } from "@lingui/react";
import { i18n } from "./locales/i18n";
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { UserProvider, useUser } from "./provider/user.provider";
import { ReOneThemeProvider, theme } from "reonelabs-ui";
import { setContext } from "@apollo/client/link/context";
import styled from "styled-components";
import { onError } from "@apollo/client/link/error";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { Loader } from "./component/element/Loader";
import { TattooSalonProvider } from "./provider/tattooSalon.provider";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { DiscussionProvider } from "@provider/discussion.provider";
import { TattooFilterProvider } from "@provider/tattooFilter.provider";
import { OpinionProvider } from "@provider/opinion.provider";

const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

/**
 * Sets the authorization header on every request
 */
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

/**
 * Handles GraphQL errors and removes the user token if the error message contains the word "User not authorized".
 * @param {{ graphQLErrors: GraphQLErrors[]; networkError: NetworkError; }} - The error object
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message.includes("User not authorized")) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const removeTypenameLink = removeTypenameFromVariables();

type FeatureFlagProvideProps = {
  children?: ReactNode;
};

/**
 * A component that provides feature flags to its children. It uses the `useLDClient` and `useUser` hooks to get the `ldClient` and `userConnected` values.
 * It then uses the `useEffect` hook to identify the user with the `ldClient` when the `ldClient` and `userConnected.email` values change.
 * The component renders its children.
 *
 * @param {FeatureFlagProvideProps} props - The props for the component.
 * @return {JSX.Element} The component that provides feature flags to its children.
 */
function FeatureFlagProvide({ children }: FeatureFlagProvideProps) {
  const ldClient = useLDClient();
  const { userConnected } = useUser();
  useEffect(() => {
    if (!ldClient) return;
    if (!userConnected) return;
    ldClient.identify({
      kind: "user",
      key: userConnected.email,
      name: userConnected.email,
    });
  }, [ldClient, userConnected]);
  return <>{children}</>;
}

const TATTOO_TOUR_THEME = {
  ...theme,
  light: {
    ...theme.light,
    colors: {
      ...theme.light.colors,
      primaryBrand: {
        lowest: "#e6e6e6",
        low: "#A3A3A3",
        medium: "#0A0A0A",
        high: "#0A0A0A",
        highest: "#e6e6e6",
      },
    },
  },
};

type AppProviderProps = {
  children?: ReactNode;
};

/**
 * AppProvider function that provides the entire app with necessary providers and components.
 *
 * @param {AppProviderProps} children - The child components to be wrapped by the provider
 * @return {JSX.Element} The wrapped child components with necessary providers
 */
export function AppProvider({ children }: AppProviderProps) {
  const queryClient = new QueryClient();
  const httpLink = new HttpLink({
    uri: `${apiUrl}/graphql`,
  });
  const client = new ApolloClient({
    link: ApolloLink.from([removeTypenameLink, authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <ReOneThemeProvider defaultDarkMode="light" customTheme={TATTOO_TOUR_THEME}>
          <ApolloProvider client={client}>
            <I18nProvider i18n={i18n}>
              <GoogleOAuthProvider clientId={GOOGLE_CONFIG.CLIENT_ID}>
                <AuthProvider>
                  <UserProvider>
                    <TattooFilterProvider>
                      <TattooSalonProvider>
                        <DiscussionProvider>
                          <OpinionProvider>
                            <FeatureFlagProvide>
                              <AppLayout>
                                <RouterProvider router={router} />
                                {children}
                                <ToastContainer />
                              </AppLayout>
                            </FeatureFlagProvide>
                          </OpinionProvider>
                        </DiscussionProvider>
                      </TattooSalonProvider>
                    </TattooFilterProvider>
                  </UserProvider>
                </AuthProvider>
              </GoogleOAuthProvider>
            </I18nProvider>
          </ApolloProvider>
        </ReOneThemeProvider>
      </Suspense>
    </QueryClientProvider>
  );
}

const AppLayout = styled.div``;
