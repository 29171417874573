import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useAuth } from "../../../provider/auth.provider";
import { useNavigate } from "@tanstack/react-router";
import { useUser } from "../../../provider/user.provider";

/**
 * GoogleAuth function for handling Google authentication.
 *
 * @param {CredentialResponse} credentialResponse - the response containing the credential
 * @return {JSX.Element} the GoogleLogin component for Google authentication
 */
export function GoogleAuth() {
  const { connectUser } = useAuth();
  const navigate = useNavigate();
  const { userConnectedRefetch } = useUser();

  /**
   * Handles the connection using the provided credential response.
   *
   * @param {CredentialResponse} credentialResponse - the credential response object
   * @return {Promise<void>}
   */
  async function handleConnect(credentialResponse: CredentialResponse) {
    if (!credentialResponse.credential) return;
    localStorage.setItem("token", credentialResponse.credential);
    connectUser.mutate(
      {
        token: credentialResponse.credential,
      },
      {
        onSuccess: (response) => {
          if (!response.data) return;

          const user = response.data;

          delete user._id;

          // setup the user in the localstorage
          localStorage.setItem("user", JSON.stringify(user));
          // check if the subscription is active, and suggest the user to subscribe if it isn't
          navigate({ to: "/" });

          if (!response.data.email) return;
          userConnectedRefetch({
            email: response.data.email,
          });
        },
      },
    );
  }

  return <GoogleLogin useOneTap onSuccess={handleConnect} />;
}
